<template>
  <EntityList
    :config="config"
    :columns="columns"
    :formatted-columns="formattedColumns"
    :filters="filters"
    :filter-vals.sync="filterVals"
    :extra-actions="extraActions"
    :allow-duplicate="false"
    :has-insert-btn="false"
    :custom-query="{
      selleronly: true,
    }"
    authority="Products_Product"
    single-edit
    inline-actions
    hide-edit
    hide-delete
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'

import storeModule from '../partnerProductsStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      sellerOptions: [],
      categoriesOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'products/sell-requests',
        endpoint: 'products/sell-requests',
        route: 'products/sell-requests',
        title: {
          single: this.$i18n.t('Product'),
          plural: this.$i18n.t('Products'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: '#', sortable: true },
        { key: 'featuredImg', label: this.$i18n.t('photo'), sortable: false },
        { key: 'title', label: this.$i18n.t('name'), sortable: true },
        { key: 'code', label: this.$i18n.t('code'), sortable: true },
        { key: 'regularPrice', label: this.$i18n.t('price'), sortable: true },
        { key: 'actions', label: this.$i18n.t('actions') },
      ]
    },
    formattedColumns() {
      return [
        { key: 'published', type: 'status' },
        { key: 'featuredImg', type: 'image', width: 80 },
      ]
    },
    extraActions() {
      return [
        {
          icon: 'EyeIcon',
          title: 'View in e-shop',
          // eslint-disable-next-line prefer-template
          href: process.env.VUE_APP_SHOP_URL + 'product/',
          appendField: 'eshopSlug',
          variant: 'outline-secondary',
          renderCondition: {
            key: 'published',
            value: true,
          },
        },
      ]
    },

    filters() {
      return [
        {
          name: 'seller',
          label: this.$t('seller'),
          options: this.sellerOptions,
        },
        {
          name: 'category',
          label: this.$t('category'),
          options: this.categoriesOptions,
        },
        {
          name: 'createdfrom',
          type: 'date',
          label: this.$i18n.t('Created From'),
          value: null,
          maxDateFieldIdx: '3',
          maxDate: new Date(),
        },
        {
          name: 'createdto',
          type: 'date',
          label: this.$i18n.t('Created To'),
          value: null,
          minDateFieldIdx: '2',
          maxDate: new Date(),
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchSellers').then(response => {
      this.sellerOptions = response.data
    })
    this.$store.dispatch('app-common/fetchCategories').then(response => {
      this.categoriesOptions = response.data
    })
  },
}
</script>
